import React, { useState } from 'react'
import BusinessImpactGapsContext from './businessImpactGapsContext'
const BusinessImapctGaps_Provider = ({ children }) => {
  const [payLoadGaps, setPayLoadGaps] = useState({
    id: 0,
    gapsPriorityOrder: 0,
    gaps: '',
    level: 'NonL0',
    gapsShortDescription: '',
    gapsDescription: '',
    impact: '',
    urgency: '',
    gapSource: '',
    services: '',
    issuesIdentified: '',
    gapsIdentified: '',
    recommendations: '',
    impactsObserved: '',
    isDeleted: 'N',
    updatedBy: '',
  })
  const [visible, setVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [busiModalvisible, setBusiModalVisible] = useState(false)
  const [visibleGapService, setVisibleGapService] = useState(false)
  const [popupData, setPopupData] = useState(null)
  const [busiImpactActiveTab, setBusiImpactActiveTab] = useState('')
  const [gapsDetailsByMonthYear, setgapsDetailsByMonthYear] = useState([])
  //console.log(priceOnline)
  return (
    <BusinessImpactGapsContext.Provider
      value={{
        payLoadGaps,
        setPayLoadGaps,
        visible,
        setVisible,
        isSuccess,
        setIsSuccess,
        busiModalvisible,
        setBusiModalVisible,
        visibleGapService,
        setVisibleGapService,
        popupData,
        setPopupData,
        busiImpactActiveTab,
        setBusiImpactActiveTab,
        gapsDetailsByMonthYear,
        setgapsDetailsByMonthYear,
      }}
    >
      {children}
    </BusinessImpactGapsContext.Provider>
  )
}
export default BusinessImapctGaps_Provider
