import React, { useState } from 'react'
import GuideLineContext from './guideLineContext'
const GuideLine_Provider = ({ children }) => {
  const [payLoadGuideline, setPayLoadGuideline] = useState({
    id: 0,
    pageId: 0,
    title: '',
    description: '',
    mmr: '',
    whyRelevant: '',
    recommendation: '',
    referenceLinks: '',
    isDeleted: 'N',
    updatedBy: '',
  })
  const [guidelineList, setGuidelineList] = useState([{}])
  const [visible, setVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  //console.log(priceOnline)
  return (
    <GuideLineContext.Provider
      value={{
        payLoadGuideline,
        guidelineList,
        setGuidelineList,
        setPayLoadGuideline,
        visible,
        setVisible,
        isSuccess,
        setIsSuccess,
      }}
    >
      {children}
    </GuideLineContext.Provider>
  )
}
export default GuideLine_Provider
