import React, { useRef, useState } from 'react'
import ApplicationContext from './applicationContext'
const Application_Provider = ({ children }) => {
  const [payLoadApplication, setPayLoadApplication] = useState({
    id: 0,
    tech_Center: '',
    unit: '',
    productArea: 'NonL0',
    productTeam: '',
    application: '',
    service: '',
    responsibleName: '',
    responsibleEmail: '',
    applicationOwnerName: '',
    applicationOwnerEmail: '',
    MMRPhase: '',
    ARSService: '',
    isDeleted: 'N',
    updatedBy: '',
  })
  const [visible, setVisible] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const containerRef = useRef(null)
  //console.log(priceOnline)
  return (
    <ApplicationContext.Provider
      value={{
        payLoadApplication,
        setPayLoadApplication,
        visible,
        setVisible,
        isSuccess,
        setIsSuccess,
        containerRef,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  )
}
export default Application_Provider
