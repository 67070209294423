import React, { useState } from 'react'
import IncidentContext from './incidentContext'
const Incident_Provider = ({ children }) => {
  //console.log('children', children)
  const [getIncident, setGetIncident] = useState(true)
  const [visible, setVisible] = useState(false)
  const [isSucess, setIsSucess] = useState(false)
  const [pillarArray, setPillarArray] = useState([])
  const [incidentData, setIncidentData] = useState()
  const [incidentNumber, setIncidentNumber] = useState()
  const [incidentPostData, setIncidentPostData] = useState({
    services: [],
    tech_Centers: [],
    priority: [],
    category: [],
    pillar: [],
    incidentNumber: [],
    monthYear: '',
  })
  //console.log(priceOnline)
  return (
    <IncidentContext.Provider
      value={{
        getIncident,
        visible,
        isSucess,
        pillarArray,
        incidentData,
        incidentPostData,
        incidentNumber,
        setIncidentNumber,
        setIncidentPostData,
        setIncidentData,
        setPillarArray,
        setIsSucess,
        setGetIncident,
        setVisible,
      }}
    >
      {children}
    </IncidentContext.Provider>
  )
}
export default Incident_Provider
