export const environmentData = {
  run: 'production',
  development: {
    clientId: '9e9325f7-4c4d-43e6-a9f4-a47ba623cc92',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9e9325f7-4c4d-43e6-a9f4-a47ba623cc92/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=05135d80-faac-481e-bfbb-2336ef105bb2;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    apiBaseURL: 'https://dev-api-execdb-ps.azurewebsites.net/api',
    apiBaseURLMMR: 'https://dev-api-hm-mmrmms.azurewebsites.net/api',
  },
  staging: {
    clientId: '9e9325f7-4c4d-43e6-a9f4-a47ba623cc92',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9e9325f7-4c4d-43e6-a9f4-a47ba623cc92/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=05135d80-faac-481e-bfbb-2336ef105bb2;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    apiBaseURL: 'https://dev-api-execdb-ps.azurewebsites.net/api',
    apiBaseURLMMR: 'https://dev-api-enterprisedashboard-mmrmms.azurewebsites.net/api',
  },
  production: {
    clientId: '9e9325f7-4c4d-43e6-a9f4-a47ba623cc92',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9e9325f7-4c4d-43e6-a9f4-a47ba623cc92/User.Impersonation',
    appInsightConnectionString:
      'InstrumentationKey=6e141473-8dda-4a09-849d-bbe0127c40a0;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    apiBaseURL: 'https://prod-api-execdb-ps.azurewebsites.net/api',
    apiBaseURLMMR: 'https://prod-api-hm-mmrmms.azurewebsites.net/api',
  },
}
